import React, {useState, useEffect} from "react"

const ContactForm = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("")
  const [success, setSuccess] = useState("")
  const [robot, setRobot] = useState("")
  const [errors, setErrors] = useState([])

  const buttonClass = () => {
    return success == 'ok' ? "success" : ""
  }

  const buttonText = () => {
    return success == 'ok' ? "Sent" : "Submit"
  }

  const submitMessage = () => {
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
      return fetch('/contacts', {
        method: "POST",
        headers: {
          "Content-Type": "application/json;",
          'X-CSRF-Token': csrf
        },
        body: JSON.stringify({
          contact: {
            name: name,
            email: email,
            message: message
          }
        })
      }).then((res) => {
      if (res.status == 200) {
          res.json().then(json => {
            setSuccess('ok')
            setErrors([])
          });
      } else {
        res.json().then(json => {
          setErrors(json.error)
        });
      }
    })
  }
	return (
    <>
    	<div>
    		<label>Name</label>
    		<input type="text" onChange={(e) => setName(e.target.value)} />

    		<label>Email</label>
    		<input type="text" onChange={(e) => setEmail(e.target.value)} />

    		<label>Message</label>
    		<textarea  onChange={(e) => setMessage(e.target.value)} resize="vertical" rows="4"></textarea>

        <label>Are you a robot?</label>
        <input type="text" onChange={(e) => setRobot(e.target.value)} />


        { errors.length > 0 && 
          <div className="callout lightalert">
            <ul>
            {errors.map((err) => (
              <li key={Math.random()}>{err}</li>
            ))}
            </ul>
          </div>
        }

        <button className={`button wide ${buttonClass()}`} onClick={() => submitMessage()}>{buttonText()}</button>
    	</div>
    </>
   )
}

export default ContactForm;