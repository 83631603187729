import React, { useState, useRef, useMemo } from "react";
import {
  isEmailValid,
  isNameValid,
  getTomorrowDate,
  isDateInThePastOrToday,
} from "./utils";

import StripeForm from "./StripeForm";

const BookingForm = () => {
  // current active section
  const [activeSection, setActiveSection] = useState(1);
  // section 1
  const [name, setName] = useState("");
  const [isNameParamValid, setIsNameParamValid] = useState(true);
  const [email, setEmail] = useState("");
  const [isEmailParamValid, setIsEmailParamValid] = useState(true);
  // section 2
  const [details, setDetails] = useState("");
  const [isDetailsParamValid, setIsDetailsParamValid] = useState(true);
  const section2Ref = useRef(null);
  // section 3
  const [date, setDate] = useState("");
  const [isDateParamValid, setIsDateParamValid] = useState(true);
  const [time, setTime] = useState("");
  const [isTimeParamValid, setIsTimeParamValid] = useState(true);
  // section 4
  const [companyName, setCompanyName] = useState("");
  const [ein, setEin] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const section4Ref = useRef(null);

  const dataFromAllSections = useMemo(() => {
    return {
      name,
      email,
      details,
      date,
      time,
      companyName,
      ein,
      vatNumber,
      country,
      address,
    };
  }, [
    name,
    email,
    details,
    date,
    time,
    companyName,
    ein,
    vatNumber,
    country,
    address,
  ]);

  //  const submitMessage = () => {
  //    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
  //      return fetch('/contacts', {
  //        method: "POST",
  //        headers: {
  //          "Content-Type": "application/json;",
  //          'X-CSRF-Token': csrf
  //        },
  //        body: JSON.stringify({
  //          contact: {
  //            name: name,
  //            email: email,
  //            message: message
  //          }
  //        })
  //      }).then((res) => {
  //      if (res.status == 200) {
  //          res.json().then(json => {
  //            setSuccess('ok')
  //            setErrors([])
  //          });
  //      } else {
  //        res.json().then(json => {
  //          setErrors(json.error)
  //        });
  //      }
  //    })
  //  }

  const sectionActive = (section) => {
    return activeSection == section ? "active" : "";
  };

  const getSectionRef = (section) => {
    switch (section) {
      case 2:
        return section2Ref;
      case 4:
        return section4Ref;
      default:
        break;
    }
  };

  const validate_section = (section) => {
    if (section === 1) {
      let hasErrorOccurred = false;
      if (isNameValid(name)) {
        setIsNameParamValid(false);
        hasErrorOccurred = true;
      } else {
        setIsNameParamValid(true);
      }

      if (isEmailValid(email)) {
        setIsEmailParamValid(false);
        hasErrorOccurred = true;
      } else {
        setIsEmailParamValid(true);
      }
      return !hasErrorOccurred;
    } else if (section === 2) {
      let hasErrorOccurred = false;
      if (details.length < 300) {
        setIsDetailsParamValid(false);
        hasErrorOccurred = true;
      } else {
        setIsDetailsParamValid(true);
      }
      return !hasErrorOccurred;
    } else if (section === 3) {
      let hasErrorOccurred = false;
      if (!date || isDateInThePastOrToday(date)) {
        setIsDateParamValid(false);
        hasErrorOccurred = true;
      } else {
        setIsDateParamValid(true);
      }
      if (!time) {
        setIsTimeParamValid(false);
        hasErrorOccurred = true;
      } else {
        setIsTimeParamValid(true);
      }
      return !hasErrorOccurred;
    } else if (section === 4) {
      return true;
    } else if (section === 5) {
      return true;
    }
  };

  const triggerSectionChange = (event, new_section, isButtonClick) => {
    event.preventDefault(event);
    if (isButtonClick) {
      const sectionToBeValidated = new_section - 1;
      const validationOk = validate_section(sectionToBeValidated);
      if (validationOk) {
        setActiveSection(new_section);
        if (getSectionRef(new_section)) {
          getSectionRef(new_section).current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      }
    }
    // else if (true) {
    else if (new_section <= activeSection) {
      setActiveSection(new_section);
    }
  };

  return (
    <>
      <div>
        <ul className="accordion booking-form">
          <li className={`accordion-item ${sectionActive(1)}`}>
            <a
              href="#"
              className="accordion-title"
              onClick={(e) => triggerSectionChange(e, 1, false)}
            >
              Contact information
            </a>

            <div className="accordion-content">
              <div className="label-with-error">
                <label>Name</label>
                {!isNameParamValid && (
                  <div className="booking-form-error">Invalid Name</div>
                )}
              </div>
              <input
                type="text"
                placeholder="John Doe"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className="label-with-error">
                <label>Email</label>
                {!isEmailParamValid && (
                  <div className="booking-form-error">Invalid Email</div>
                )}
              </div>
              <input
                type="text"
                placeholder="john@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="button no-margin wide tiny"
                onClick={(e) => triggerSectionChange(e, 2, true)}
              >
                Next
              </button>
            </div>
          </li>

          <li className={`accordion-item ${sectionActive(2)}`}>
            <a
              ref={section2Ref}
              href="#"
              className="accordion-title"
              onClick={(e) => triggerSectionChange(e, 2, false)}
            >
              Agenda
            </a>

            <div className="accordion-content">
              <p>
                Tell us more details about the problem you are trying to solve.
                The more you tell us, the better prepared we'll be.
              </p>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <label>Details</label>
                <div style={{ fontSize: 12 }}>Characters: {details.length}</div>
                {!isDetailsParamValid && (
                  <div className="booking-form-error">
                    Must be at least 300 characters
                  </div>
                )}
              </div>
              <textarea
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                resize="vertical"
                rows="10"
              ></textarea>
              <button
                className="button no-margin wide tiny"
                onClick={(e) => triggerSectionChange(e, 3, true)}
              >
                Next
              </button>
            </div>
          </li>

          <li className={`accordion-item ${sectionActive(3)}`}>
            <a
              href="#"
              className="accordion-title"
              onClick={(e) => triggerSectionChange(e, 3, false)}
            >
              Date and time
            </a>

            <div className="accordion-content">
              <div className="label-with-error">
                <label>Date</label>
                {!isDateParamValid && (
                  <div className="booking-form-error">
                    Earliest date available is tomorrow
                  </div>
                )}
              </div>
              <input
                type="date"
                min={getTomorrowDate()}
                onChange={(e) => setDate(e.target.value)}
              />
              <div className="label-with-error">
                <label>Time</label>
                {!isTimeParamValid && (
                  <div className="booking-form-error">Select a valid time</div>
                )}
              </div>
              <input type="time" onChange={(e) => setTime(e.target.value)} />
              <button
                className="button no-margin wide tiny"
                onClick={(e) => triggerSectionChange(e, 4, true)}
              >
                Next
              </button>
            </div>
          </li>

          <li className={`accordion-item ${sectionActive(4)}`}>
            <a
              ref={section4Ref}
              href="#"
              className="accordion-title"
              onClick={(e) => triggerSectionChange(e, 4, false)}
            >
              Company details (optional)
            </a>

            <div className="accordion-content">
              <label>Company name</label>
              <input
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />

              <label>EIN</label>
              <input
                type="text"
                value={ein}
                onChange={(e) => setEin(e.target.value)}
              />

              <label>VAT Number</label>
              <input
                type="text"
                value={vatNumber}
                onChange={(e) => setVatNumber(e.target.value)}
              />

              <label>Country</label>
              <input
                type="text"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />

              <label>Address</label>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />

              <button
                className="button no-margin wide tiny"
                onClick={(e) => triggerSectionChange(e, 5, true)}
              >
                Next
              </button>
            </div>
          </li>

          <li className={`accordion-item ${sectionActive(5)}`}>
            <a
              href="#"
              className="accordion-title"
              onClick={(e) => triggerSectionChange(e, 5, false)}
            >
              Payment
            </a>

            <div className="accordion-content">
              <StripeForm data={dataFromAllSections} />
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default BookingForm;
