export const isNameValid = (name) => {
  const nameRegex = /^[A-Z][a-z]+\s[A-Z][a-z]+$/g;
  return !nameRegex.test(name);
};

export const isEmailValid = (email) => {
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return !emailRegex.test(email);
};

export const getTomorrowDate = () => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");
  return year + "-" + month + "-" + day;
};

export function isDateInThePastOrToday(dateString) {
  let inputDate = new Date(dateString);
  let today = new Date();

  return today >= inputDate;
}

export const createPaymentIntent = (email) => {
  let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

  fetch(`/bookings`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;",
      "X-CSRF-Token": csrf,
    },
    body: JSON.stringify({
      email: email,
      amount: 40,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      return response.client_secret;
    })
    .catch((error) => {
      console.log(error);
    });
};
