import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "foundation-sites"
import Chart from 'chart.js/auto';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// require("~/assets/stylesheets/application")
//= require jquery

import hljs from 'highlight.js/lib/core';
import elixir from 'highlight.js/lib/languages/elixir';
import ruby from 'highlight.js/lib/languages/ruby';
import python from 'highlight.js/lib/languages/python';
import javascript from 'highlight.js/lib/languages/javascript';
hljs.registerLanguage('elixir', elixir);
hljs.registerLanguage('ruby', ruby);
hljs.registerLanguage('python', python);
hljs.registerLanguage('javascript', javascript);

$(document).on('turbolinks:load', function() {
  $(document).foundation();


(async function() {
  const data = [
    { year: 2016, count: 8.9 },
    { year: 2017, count: 11.31 },
    { year: 2018, count: 14.60 },
    { year: 2019, count: 18.29 },
    { year: 2020, count: 22.57 },
    { year: 2021, count: 28.67 },
    { year: 2022, count: 39.59 },
    { year: 2023, count: 51.28 },
    { year: 2024, count: 62.78 },
    { year: 2025, count: 77.77 },
    { year: 2026, count: 96.77 },
  ];

  new Chart(
    document.getElementById('acquisitions'),
    {
      type: 'bar',
      data: {
        labels: data.map(row => row.year),
        datasets: [
          {
            label: 'Cannabis revenue by year in € billions',
            data: data.map(row => row.count),
            backgroundColor: ['#81d6de', '#81d6de', '#81d6de', '#81d6de', '#81d6de', '#81d6de', '#81d6de', '#81d6de', '#81d6de', '#5ce298', '#5ce298',]
          }
        ]
      }
    }
  );
})();


  $('.scroll_to').click(function(event){
    event.preventDefault(event);
    $('html, body').animate({scrollTop: $($(this).attr('href')).offset().top }, 650);
  });
  
  $('#nav-toggler').click(function(event){
    event.preventDefault(event);
    event.stopPropagation(event);
    event.stopImmediatePropagation(event);
    if( $(this).hasClass("toggled") ){
      hideNav();
    }else{
      showNav();
    }
  });

  function showNav(){
    $('#nav-toggler').addClass('toggled');
    $('nav ul').slideDown(350);
    // idk why.. but this doesn't seem to be needed anymore
    //$('body').addClass('push_down');
    $('.nav_link_wrapper').addClass('is_toggled');
  }

  function hideNav(){
    $('#nav-toggler').removeClass('toggled');
    $('nav ul').slideUp(350);
    setTimeout(function(){
      $(this).removeClass('toggled')
    }, 350)
    // same - idk why.. but this doesn't seem to be needed anymore
    //$('body').removeClass('push_down')
  }

// Тhis will highlight the content all occurrences of <pre><code> blocks
// every time a page loads. It manually finds code blocks, edits their contents and highlights them.
  document.querySelectorAll('pre code').forEach((el) => {
    el.innerHTML = el.innerHTML.replace(/<\/*em>/gi, '_').replace(/<[^>]*>/gi,'');
    hljs.highlightElement(el);
  });

});
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
