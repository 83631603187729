import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { createPaymentIntent } from "./utils";

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const CARD_OPTIONS = {
  hidePostalCode: true,
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#82D6DE",
      color: "black",
      fontWeight: 500,
      fontSize: "16px",
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
  },
};

const CardPaymentForm = (props) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return null;
    }
    const clientSecret = await createPaymentIntent(props.data.email);

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: props.data.name,
        },
      },
    });

    if (result.error) {
      setError(result.error.message);
      setSuccess(false);
    } else {
      setError(null);
      setSuccess(true);
      // const allData = { ...props.data, token: result.token };
      // console.log(allData, "submit");
      console.log(result, "result");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={CARD_OPTIONS} />
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>Payment Successful!</p>}
      <button
        type="submit"
        disabled={!stripe}
        style={{ marginTop: "20px" }}
        className={`button wide`}
      >
        submit
      </button>
    </form>
  );
};

const StripeForm = (props) => {

  return (
    <Elements stripe={stripePromise}>
      <CardPaymentForm data={props.data} />
    </Elements>
  );
};

export default StripeForm;
